html, body {
  height: 100%;
  --nav-height: 48px;
  --bottom-nav-height: 56px;
  --spacing-big: 16px;
  margin: 0;
  padding: 0;
}

.nav {
  height: var(--nav-height);
  z-index: 99999;
}

.page {
  top: --nav-height;
  width: 100%;
  height: calc(100% - var(--bottom-nav-height)  - var(--nav-height));
  position: absolute;
  overflow-y: auto;
}

.bottom-nav {
  height: var(--bottom-nav-height);
  width: 100%;
  position: absolute;
  bottom: 0;
}

.configure button {
  width: 100%;
}

.configure .cell-button {
  width: 64px;
}

.configure .question {
  padding-left: 14px;
}

.score-bar-score {
  width: 100%;
  align-self: flex-end;
  display: flex;
}

.score-bar {
  height: 20px;
  width: 100%;
  background-color: #d3d3d3;
  display: flex;
}

/*# sourceMappingURL=index.6fc7f1f9.css.map */
