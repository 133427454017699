html, body {
    padding: 0px;
    margin: 0px;
    height: 100%;
    --nav-height:48px;
    --bottom-nav-height:56px;
    --spacing-big:16px;
}
 
.nav {
    height: var(--nav-height);
    z-index: 99999;
}

.page {
    position: absolute;
    top: --nav-height;
    width: 100%;
    overflow-y: auto;
    height: calc(#{100%} - var(--bottom-nav-height) - var(--nav-height));
}

.bottom-nav {
    position: absolute;
    height: var(--bottom-nav-height);
    bottom: 0px;
    width: 100%;
}

.configure {
    button {
        width: 100%;
    }
    .cell-button {
        width: 64px;
    }
    .question {
        padding-left: 14px;
    }
}

.score-bar-score {
    width: 100%;
    display: flex;
    align-self: flex-end;
}

.score-bar {
    display: flex;
    height: 20px;
    width: 100%;
    background-color: lightgrey;
}